<template>
  <div id="about">
    <section>
      <div class="row heading">
        <div class="col-md">
          <h2>About Us</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-md">
          <p>
            This is the online hub for the Labrador Straits Community
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  name: 'About',
}
</script>
